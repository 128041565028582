<template>
  <div>
    <div id="content">
      <Splash title="none" :bannerImage="post[0].better_featured_image.source_url"/>
      <div class="ourContainer">
        <div class='wp'>
          <b-row>
            <b-col lg="8" md='8' cols='12' order='2' order-md='1' order-lg='1'>
              <template v-if="dis !== undefined">
                <ol class="noPadding">
                  <b-nav-item class="back" :to="'/our-sports/' + dis + '/news'"
                    >Back to all {{ dis }} news</b-nav-item
                  >
                </ol>
              </template>
              <template v-if="dis === undefined">
                <ol class="noPadding">
                  <b-nav-item class="back" to="/news"
                    > 
                    <font-awesome-icon class="icon" :icon="['fa', 'chevron-left']"/>
                    Back To News</b-nav-item
                  >
                </ol>
              </template>
              <h1 class="date publishedDate">{{ dateTime }}</h1>
              <h2 class="newsTitle" :class="dis" v-html="post[0].title.rendered">
              </h2>
            </b-col>
          </b-row>
          <span class="wpContent" v-html="post[0].content.rendered"></span>
          <!-- <div class="hidedesk" v-if="post[0].content.rendered.length > 0">
            <span v-bind:class="[isActive ? 'noexpand' : 'expand']" class="wpContent" v-html="post[0].content.rendered.substring(0,1000)"></span>
            <div>
              <b-button @click="Colorcss()" class="readMoreBtn" v-b-toggle.collapse-1 variant="primary">Read More</b-button>
              <b-collapse id="collapse-1" class="mt-2 wpcontentHeight readMoreContent">
                <span class="wpContent" v-html="post[0].content.rendered.substring(1000,100000)"></span>
              </b-collapse>
              <b-button @click="Colorcss()" class="readLessBtn" v-b-toggle.collapse-1 variant="primary">Read Less</b-button>
            </div>
          </div> -->
        </div>
      </div>
    </div>    
    <Footer />
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
import Splash from '@/components/splash.vue'
import Footer from '@/components/footer.vue'
export default {
  name: "newsStory",
  components: { Splash, Footer },
  props: ["slug", "dis"],
  data() {
    return {
      post: [],
      isActive: true,
      title: '',
      description: ''
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  computed: {
    route: function() {
      var r = this.$route.fullPath.split("/");
      if (r[0] === "") {
        r[0] = "home";
      }
      return r;
    },
    dateTime: function() {
      var dateOG = this.post[0].date.split("T");
      var splitDate = dateOG[0].split("-");
      var newDate = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0];
      return newDate;
    },
  },
  methods: {
    routeTitle(item) {
      var low = item;
      var rep = low.replace(/-/g, " ");
      return rep;
    },
    Colorcss: function(){
       this.isActive = !this.isActive;
    }
  },
  mounted() {
    apiCall
      .wpInfo("wp/v2/posts?slug=" + this.slug)
      .then(({ data }) => {
        this.post = data;
        this.title = data[0].title.rendered;
        this.description = data[0].yoast_head_json.og_description;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.noPadding {
  padding-left: 0;
}
::v-deep figure.wp-block-image.size-normal > img {
  height: auto;
}
::v-deep figure.wp-block-image.size-small > img {
  height: auto;
}
::v-deep figure.wp-block-image.size-large > img {
  height: auto;
}
::v-deep figure.wp-block-image.size-full > img {
  height: auto;
}
::v-deep .hidedesk > span.noexpand > p:last-child {
  opacity: .3;
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}
::v-deep .hidedesk > span.expand > p:last-child {
  opacity: 1;
}
.readMoreContent > .wpContent {
  color: #707070;
}
button.btn.readMoreBtn.btn-primary.not-collapsed {
  display: none;
}
button.btn.readLessBtn.btn-primary.collapsed {
  display: none;
}
.wpcontentHeight {
  /* margin-top: 30px!important; */
  display: block;
}
.readMoreBtn {
  background-color: #201646!important;
  margin-top: 25px;
  margin-bottom: 25px;
  border-color: white;
}
.readLessBtn {
  background-color: #201646!important;
  margin-top: 25px;
  margin-bottom: 25px;
  border-color: white;
}
h1.publishedDate {
  color: #1f1545 !important;
  padding-bottom: 10px;
  padding-top: 5px;
  font-weight: 100;
  font-size: 10pt;
}
#content {
  padding-left: 20%;
}
.overlay {
  background-color: #201546;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/HomePage_Overlay_1@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.splash {
  opacity: 0.46;
  height: 603px;
  object-fit: cover;
}
.title {
  position: absolute;
  top: 277px;
  width: 100%;
  text-align: center;
  height: 75px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000058;
  font-weight: bold;
  font-size: 3.5rem;
}
.route {
  display: inline-flex;
}
.route > li > a {
  padding: 0.5rem 0;
  text-transform: uppercase; 
  font-weight: bold;
}
.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
::v-deep .wpContent > h2 {
  padding: 30px 0;
  color: #201546;
  font-size: 24px;
  font-weight: 500;
}
::v-deep .wpContent > p {
  color: #201546;
  font-size: 16px;
}
::v-deep .wpContent > h2 > strong{
  font-weight: 500!important;
}
::v-deep .wpContent > figure {
  padding-top: 20px;
}
.newsTitle {
  color: #201546;
  font-size: 30px;
  padding: 10px 0px 30px 0;
  font-weight: bold;
}
.date {
  padding-bottom: 30px;
}
.back {
  text-align: left;
  text-transform: capitalize;
}
.back > a {
  color: #201546!important;
  padding-left: 0px!important;
  margin-bottom: 30px;
}
.back:hover{
  font-weight: bold;
}
.hide0{
  display: none;
}
.nav-item > a {
  color: #009fe3;
}
::v-deep .wpContent>p>a{
  color: #000;
  text-decoration: underline;
}
.wp{
  /* padding-top: 50px; */
}
.swimming {
  color: #009fe3;
}
.para-swimming> {
  color: #afca0b;
}
.water-polo> {
  color: #349980;
}
.masters> {
  color: #ff9901;
}
.artistic-swimming {
  color: #f80240;
}
.diving>{
  color: #6ec9df;
}
.open-water>{
  color: #5c439c;
}
@media only screen and (min-width: 991px) {
  ::v-deep .hidedesk {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  ::v-deep .hidemob {
    display: none;
  }
  #content {
    padding-left: 0;
  }
  .back {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  ::v-deep  .ourContainer {
    max-width: none;
    margin: 0px auto;
    padding: 0 0px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  ::v-deep .splash {
    opacity: 0.46;
    height: auto!important;
    object-fit: cover;
  }
  ::v-deep .route > li:last-child > a > .name {
    text-decoration: none;
    display: none;
  }
}
</style>
