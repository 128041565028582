<template>
  <div id="news" :class="{ grey: newsPage === false }">
    <div class="ourContainer disMain" :class="{ newspage: $route.name === 'news', MobWidth: newsPage === true, MobWidthHome: newsPage === false}">
      <div class="padding">
        <template v-if="newsPage === false" >
          <b-row>
            <b-col>
              <h1 class="late">
                Latest News
              </h1>
            </b-col>
            <b-col class="HideMob">
              <!-- <b-nav-item class='all' to="/events">SEE ALL EVENTS</b-nav-item> -->
            </b-col>
          </b-row>
          <b-row class="HideDesk">
            <b-col>
              <!-- <b-nav-item style="text-align:left" class="all" to="/events">SEE ALL EVENTS</b-nav-item>   -->
            </b-col>
          </b-row>
          <b-row>
            <template v-if="$route.name === 'home'">
              <b-col 
                class="zoomOverflow"
                lg="6"
                md="6"
                cols="12"
                v-for="(post, index) in posts.slice(0, 4)"
                :key="index"
              >
                <ol class="olclass">
                  <b-nav-item :to="'/news/' + post.slug">
                    <span class="category artswim-color" v-if="post.categories[0] == '8'">Artistic Swimming</span>
                    <span class="category diving-color" v-else-if="post.categories[0] == '5'">Diving</span>
                    <span class="category masters-color" v-else-if="post.categories[0] == '6'">Masters</span>
                    <span class="category openwater-color" v-else-if="post.categories[0] == '7'">Open Water</span>
                    <span class="category paraswim-color" v-else-if="post.categories[0] == '4'">Para Swimming</span>
                    <span class="category swim-color" v-else-if="post.categories[0] == '3'">Swimming</span>
                    <span class="category waterpolo-color" v-else-if="post.categories[0] == '9'">Water Polo</span>
                    <span class="category waterpolo-color" style="background-color: black; color:#fff" v-else>General</span>
                    <b-card class="backgroundImage-News" v-bind:style="{ backgroundImage: 'url(' + post.better_featured_image.source_url + ')' }">
                    </b-card>
                    <b-card
                      img-alt="Image"
                      img-top
                      tag="article"
                      class="mb-2"
                      :class="{ grey: newsPage === false}"
                    >
                      <div class="dateAndtitle">
                        <div class="publishedDate">{{post.date.replace(/-/g, "/").substring(0, 10)}}</div>
                        <!-- <b-card-text class="card-text-home" v-html="post.title.rendered"> </b-card-text> -->
                        <h1 class="card-text-home" v-html="post.title.rendered"></h1>
                      </div>
                    </b-card>
                    <!-- <b-button :to="'/news/' + post.slug" class="rMore">Read More</b-button> -->
                  </b-nav-item>
                </ol>
              </b-col>
            </template>
            <template v-else>
              <b-col 
                class="zoomOverflow"
                lg="4"
                md="4"
                cols="12"
                v-for="(post, index) in posts.slice(0, 6)"
                :key="index"
              >
                <ol class="olclass">
                  <b-nav-item :to="'/news/' + post.slug">
                    <span class="category artswim-color" v-if="post.categories[0] == '8'">Artistic Swimming</span>
                    <span class="category diving-color" v-else-if="post.categories[0] == '5'">Diving</span>
                    <span class="category masters-color" v-else-if="post.categories[0] == '6'">Masters</span>
                    <span class="category openwater-color" v-else-if="post.categories[0] == '7'">Open Water</span>
                    <span class="category paraswim-color" v-else-if="post.categories[0] == '4'">Para Swimming</span>
                    <span class="category swim-color" v-else-if="post.categories[0] == '3'">Swimming</span>
                    <span class="category waterpolo-color" v-else-if="post.categories[0] == '9'">Water Polo</span>
                    <span class="category waterpolo-color" style="background-color: black; color:#fff" v-else>General</span>
                    <b-card class="backgroundImage-News" v-bind:style="{ backgroundImage: 'url(' + post.better_featured_image.source_url + ')' }">
                    </b-card>
                    <b-card
                      img-alt="Image"
                      img-top
                      tag="article"
                      class="mb-2"
                      :class="{ grey: newsPage === false}"
                    >
                      <div class="dateAndtitle">
                        <div class="publishedDate">{{post.date.replace(/-/g, "/").substring(0, 10)}}</div>
                        <!-- <b-card-text class="card-text-home" v-html="post.title.rendered"> </b-card-text> -->
                        <h1 class="card-text-home" v-html="post.title.rendered"></h1>
                      </div>
                    </b-card>
                    <!-- <b-button :to="'/news/' + post.slug" class="rMore">Read More</b-button> -->
                  </b-nav-item>
                </ol>
              </b-col>
            </template>

          </b-row>
        </template>
        <template v-if="newsPage === true && cat === 'none'">
          <b-row>
            <b-col
              class="zoomOverflow"
              lg="4"
              md="4"
              cols="12"
              v-for="(post, index) in posts.slice(0,9)"
              :key="index"
              style="margin-bottom: 1em!important;"
            >
              <ol class="olclass">
                <b-nav-item :to="'/news/' + post.slug">
                  <span class="category artswim-color" v-if="post.categories[0] == '8'">Artistic Swimming</span>
                  <span class="category diving-color" v-else-if="post.categories[0] == '5'">Diving</span>
                  <span class="category masters-color" v-else-if="post.categories[0] == '6'">Masters</span>
                  <span class="category openwater-color" v-else-if="post.categories[0] == '7'">Open Water</span>
                  <span class="category paraswim-color" v-else-if="post.categories[0] == '4'">Para Swimming</span>
                  <span class="category swim-color" v-else-if="post.categories[0] == '3'">Swimming</span>
                  <span class="category waterpolo-color" v-else-if="post.categories[0] == '9'">Water Polo</span>
                  <span class="category waterpolo-color" style="background-color: black; color:#fff" v-else>General</span>
                  <b-card class="backgroundImage-News" v-bind:style="{ backgroundImage: 'url(' + post.better_featured_image.source_url + ')' }">
                  </b-card>
                  <b-card
                    img-alt="Image"
                    img-top
                    tag="article"
                    class="mb-2"
                    :class="{ grey: newsPage === false}"
                  >
                    <div class="dateAndtitle">
                      <div class="publishedDate">{{post.date.replace(/-/g, "/").substring(0, 10)}}</div>
                      <!-- <b-card-text class="card-text-home" v-html="post.title.rendered"> </b-card-text> -->
                      <h1 class="card-text-home" v-html="post.title.rendered"></h1>
                    </div>
                  </b-card>
                </b-nav-item>
              </ol>
            </b-col>
          </b-row>
          <b-row v-show="isShowing">
            <b-col
              class="zoomOverflow"
              lg="4"
              md="4"
              cols="12"
              v-for="(post, index) in posts.slice(9,100)"
              :key="index"
              style="margin-bottom: 1em!important;"
            >
              <ol class="olclass">
                <b-nav-item :to="'/news/' + post.slug">
                  <span class="category artswim-color" v-if="post.categories[0] == '8'">Artistic Swimming</span>
                  <span class="category diving-color" v-else-if="post.categories[0] == '5'">Diving</span>
                  <span class="category masters-color" v-else-if="post.categories[0] == '6'">Masters</span>
                  <span class="category openwater-color" v-else-if="post.categories[0] == '7'">Open Water</span>
                  <span class="category paraswim-color" v-else-if="post.categories[0] == '4'">Para Swimming</span>
                  <span class="category swim-color" v-else-if="post.categories[0] == '3'">Swimming</span>
                  <span class="category waterpolo-color" v-else-if="post.categories[0] == '9'">Water Polo</span>
                  <span class="category waterpolo-color" style="background-color: black; color:#fff" v-else>General</span>
                  <b-card class="backgroundImage-News" v-bind:style="{ backgroundImage: 'url(' + post.better_featured_image.source_url + ')' }">
                  </b-card>
                  <b-card
                    img-alt="Image"
                    img-top
                    tag="article"
                    class="mb-2"
                    :class="{ grey: newsPage === false}"
                  >
                    <div class="dateAndtitle">
                      <div class="publishedDate">{{post.date.replace(/-/g, "/").substring(0, 10)}}</div>
                      <!-- <b-card-text class="card-text-home" v-html="post.title.rendered"> </b-card-text> -->
                      <h1 class="card-text-home" v-html="post.title.rendered"></h1>
                    </div>
                  </b-card>
                </b-nav-item>
              </ol>
            </b-col>
          </b-row>
          <b-col class="">
            <ol>
              <b-nav-item v-if="isShowing" class="viewAllNews" @click="isShowing ^= true">Load Less</b-nav-item>
              <b-nav-item v-else class="viewAllNews" @click="isShowing ^= true">Load More</b-nav-item>
            </ol>
          </b-col>
        </template>
        <template v-if="newsPage === true && cat !== 'none'">
          <b-row>
            <b-col
              class="zoomOverflow"
              lg="4"
              md="4"
              cols="12"
              v-for="(post, index) in posts.slice(0,9)"
              :key="index"
              style="margin-bottom: 1em !important;"
              v-show="post.categories[0] == 1"
            >
              <ol class="olclass">
                <b-nav-item :to="'/our-sports/' + cat + '/news/' + post.slug">
                <span class="category waterpolo-color" style="background-color: black; color:#fff" >General</span>
                <b-card class="backgroundImage-News" v-bind:style="{ backgroundImage: 'url(' + post.better_featured_image.source_url + ')' }">
                </b-card>
                <b-card
                  img-alt="Image"
                  img-top
                  tag="article"
                  class="mb-2"
                  :class="{ grey: newsPage === false}"
                >
                  <div class="dateAndtitle">
                    <div class="publishedDate">{{post.date.replace(/-/g, "/").substring(0, 10)}}</div>
                    <!-- <b-card-text class="card-text-home" v-html="post.title.rendered"> </b-card-text> -->
                    <h1 class="card-text-home" v-html="post.title.rendered"></h1>
                  </div>
                </b-card>
                </b-nav-item>
              </ol>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="zoomOverflow"
              lg="4"
              md="4"
              cols="12"
              v-for="(post, index) in posts.slice(0,18)"
              :key="index"
              style="margin-bottom: 1em !important;"
              v-show="post.categories[0] !== 1"
            >
              <ol class="olclass">
                <b-nav-item :to="'/our-sports/' + cat + '/news/' + post.slug">
                <span class="category swim-color" v-if="cat == 'swimming'">Swimming</span>
                <span class="category paraswim-color" v-else-if="cat == 'para-swimming'">Para Swimming</span>
                <span class="category diving-color" v-else-if="cat == 'diving'">Diving</span>
                <span class="category openwater-color" v-else-if="cat == 'open-water'">Open Water Swimming</span>
                <span class="category waterpolo-color" v-else-if="cat == 'water-polo'">Water Polo</span>
                <span class="category artswim-color" v-else-if="cat == 'artistic-swimming'">Artistic Swimming</span>
                <span class="category masters-color" v-else-if="cat == 'masters'">Masters Swimming</span>
                <b-card class="backgroundImage-News" v-bind:style="{ backgroundImage: 'url(' + post.better_featured_image.source_url + ')' }">
                </b-card>
                <b-card
                  img-alt="Image"
                  img-top
                  tag="article"
                  class="mb-2"
                  :class="{ grey: newsPage === false}"
                >
                  <div class="dateAndtitle">
                    <div class="publishedDate">{{post.date.replace(/-/g, "/").substring(0, 10)}}</div>
                    <!-- <b-card-text class="card-text-home" v-html="post.title.rendered"> </b-card-text> -->
                    <h1 class="card-text-home" v-html="post.title.rendered"></h1>
                  </div>
                </b-card>
                </b-nav-item>
              </ol>
            </b-col>
          </b-row>
          <b-row v-show="isShowing">
            <b-col
              class="zoomOverflow"
              lg="4"
              md="4"
              cols="12"
              v-for="(post, index) in posts.slice(18,100)"
              :key="index"
              style="margin-bottom: 1em !important;"
              v-show="post.categories[0] !== 1"
            >
              <ol class="olclass">
                <b-nav-item :to="'/our-sports/' + cat + '/news/' + post.slug">
                <span class="category swim-color" v-if="cat == 'swimming'">Swimming</span>
                <span class="category paraswim-color" v-else-if="cat == 'para-swimming'">Para Swimming</span>
                <span class="category diving-color" v-else-if="cat == 'diving'">Diving</span>
                <span class="category openwater-color" v-else-if="cat == 'open-water'">Open Water Swimming</span>
                <span class="category waterpolo-color" v-else-if="cat == 'water-polo'">Water Polo</span>
                <span class="category artswim-color" v-else-if="cat == 'artistic-swimming'">Artistic Swimming</span>
                <span class="category masters-color" v-else-if="cat == 'masters'">Masters Swimming</span>
                <b-card class="backgroundImage-News" v-bind:style="{ backgroundImage: 'url(' + post.better_featured_image.source_url + ')' }">
                </b-card>
                <b-card
                  img-alt="Image"
                  img-top
                  tag="article"
                  class="mb-2"
                  :class="{ grey: newsPage === false}"
                >
                  <div class="dateAndtitle">
                    <div class="publishedDate">{{post.date.replace(/-/g, "/").substring(0, 10)}}</div>
                    <!-- <b-card-text class="card-text-home" v-html="post.title.rendered"> </b-card-text> -->
                    <h1 class="card-text-home" v-html="post.title.rendered"></h1>
                  </div>
                </b-card>
                </b-nav-item>
              </ol>
            </b-col>
          </b-row>
          <b-col class="">
            <ol>
              <b-nav-item v-if="isShowing" class="viewAllNews" @click="isShowing ^= true">Load Less</b-nav-item>
              <b-nav-item v-else class="viewAllNews" @click="isShowing ^= true">Load More</b-nav-item>
            </ol>
          </b-col>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "news",
  props: ["cat", "newsPage"],
  data() {
    return {
      posts: [],
      categories: [],
      isShowing:false,
    };
  },
  watch: {
    cat: function() {
      this.update();
      this.loading = true;
    }
  },
  methods:{
    async update(){
      try {
        // First call, await for call to return
        let first_call = await apiCall.wpInfo("wp/v2/categories");
        this.categories = first_call.data;
        this.loading = false;
        // Second call, await for call to return
        let second_call = await apiCall.wpInfo(this.Cat);
        this.posts = second_call.data;
        this.loading = false;
      } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
      }
    }
  },
  computed: {
    // currentRouteName:function(temp) {
    //   if (this.$route.name === 'home') {
    //     temp = "home";
    //     console.log('home')
    //   } else {
    //     temp = "news";
    //     console.log('news')
    //   }
    //     return temp
    // },
    dateTime:function(){
      var dateOG = this.posts[0].date.split('T')
      var splitDate = dateOG[0].split('-')
      var newDate = splitDate[2]+ '/' + splitDate[1] + '/' + splitDate[0]
      return newDate

    },
    Cat: function(json) {
      if (this.cat === "none") {
        json = "wp/v2/posts?per_page=10";
      } else {
        // var set = this.categories.filter(
        //   (catO) => !catO.slug.indexOf(this.cat)
        // );
        if (this.cat === 'swimming') {
          json = "wp/v2/posts?categories=3&orderby=date&per_page=10";
        } else if (this.cat === 'artistic-swimming') {
          json = "wp/v2/posts?categories=" + 8 + '&orderby=date&per_page=10';
        } else if (this.cat === 'diving') {
          json = "wp/v2/posts?categories=" + 5 + '&orderby=date&per_page=10';
        } else if (this.cat === 'uncategorized') {
          json = "wp/v2/posts?categories=" + 1 + '&orderby=date&per_page=10';
        } else if (this.cat === 'featured-news"') {
          json = "wp/v2/posts?categories=" + 274 + ',1&orderby=date&per_page=10';
        } else if (this.cat === 'masters') {
          json = "wp/v2/posts?categories=" + 6 + '&orderby=date&per_page=10';
        } else if (this.cat === 'para-swimming') {
          json = "wp/v2/posts?categories=" + 4 + '&orderby=date&per_page=10';
        } else if (this.cat === 'water-polo') {
          json = "wp/v2/posts?categories=" + 9 + '&orderby=date&per_page=10';
        } else if (this.cat === 'open-water') {
          json = "wp/v2/posts?categories=" + 7 + '&orderby=date&per_page=10';
        }
      }
      return json;
    },
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.wpInfo("wp/v2/categories");
      this.categories = first_call.data;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.wpInfo(this.Cat);
      this.posts = second_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
};
</script>

<style scoped>
.publishedDate {
  color: #1f1545 !important;
  padding-bottom: 10px;
  padding-top: 5px;
  font-weight: 100;
  font-size: 10pt;
}
ol.olclass {
  padding: 0;
}
::v-deep .zoomOverflow.col-md-6.col-lg-6.col-12 > ol > li{
  overflow: hidden;
}
::v-deep .zoomOverflow.col-md-4.col-lg-4.col-12 > ol > li {
  overflow: hidden;
}
span.artswim-color{
  background: rgb(248, 2, 64);
  color: #fff!important ;
}
span.diving-color{
  background: rgb(110, 201, 223);
}
span.masters-color{
  background: rgb(255, 153, 1);
}
span.openwater-color{
  background: rgb(92, 67, 156);
  color: #fff!important;
}
span.paraswim-color{
  background: rgb(175, 202, 11);
}
span.swim-color{
  background: rgb(0, 159, 227);
}
span.waterpolo-color{
  background: rgb(52, 153, 128);
}

li.nav-item.viewAllNews > a{
  color: #fff;
  padding: 0.5rem 1rem;
}
li.nav-item.viewAllNews {
  background: #db044e;
  color: #fff;
  width: 150px;
  border-radius: 8px;
  margin: auto;
  margin-top: 50px;
  text-align: center;
}
li.nav-item.viewAllNews:hover {
  background: rgb(0, 159, 227);
  color: #fff;
  width: 150px;
  border-radius: 8px;
  margin: auto;
  cursor: pointer;
  margin-top: 50px;
}
span.category {
  text-transform: capitalize;
  padding: 15px;
  display: inline-block;
  border-radius: 0px 0px 15px 0px;
  position: absolute;
  z-index: 9;
  color: #201546;
  /* background: #98d8f1; */
  font-size: 10pt;
  padding-right: 25px;
  font-weight: 600;
}
.dateAndtitle {
  padding: 20px;
  height: 140px;
}
.dateAndtitle:hover {
  padding: 20px;
  height: 140px;
  background-color: #009fe3;
}
.backgroundImage-News {
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  border-radius: 0px;
  transition: transform .2s;
}
.backgroundImage-News:hover {
  transform: scale(1.5);
}
.rMore {
  background-color: #e50451;
  border: #e50451;
}
.rMore:hover {
  background-color: #009fe3;
}
::v-deep .card-subtitle.publishedDate.text-muted {
  color: #1f1545 !important;
  padding-bottom: 10px;
  padding-top: 5px;
  font-weight: 100;
  font-size: 10pt;
}
::v-deep img.card-img-top {
  max-height: 219px;
}
#news {
  padding: 50px 0;
}
.grey {
  background-color: #f7f7f7;
}
.card {
  border: 0;
}
.card-body {
  padding-left: 0;
  background: #e0f5ff;
  padding: 0px;
}
.ourContainer {
  max-width: 1350px;
  margin: 0 auto;
  padding: 0 30px;
}
.card-title {
  color: #201546;
  font-size: 20px;
  font-weight: bold;
  font-weight: 700;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-title:hover{
  text-decoration: underline;
}
.card-text{
  color: #707070;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.card-text-home {
  color: #201546;
  font-weight: 600;
  font-size: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}
.nav-item > a {
  padding: 0;
}
.late {
  padding-bottom: 40px;
  /* font-weight: bold; */
  font-family: GT-Haptik-Medium;
  color: #1f1545;
  font-size: 2.5rem;
}
.all{
  text-align: right;
  padding-right: 20px;
}
.all>a{
  color: #009fe3;
  text-decoration: underline;
  font-weight: bold;
}
.swimming>.card-body>.card-title {
  color: #009fe3;
}
.para-swimming>.card-body>.card-title {
  color: #afca0b;
}
.water-polo>.card-body>.card-title {
  color: #349980;
}
.masters>.card-body>.card-title {
  color: #ff9901;
}
.artistic-swimming>.card-body>.card-title {
  color: #f80240;
}
.diving>.card-body>.card-title {
  color: #6ec9df;
}
.open-water>.card-body>.card-title {
  color: #5c439c;
}
.all:hover{
  /* font-weight: bold; */
}
@media only screen and (max-width: 990px) {
  ::v-deep .ourContainer.MobWidthHome {
    padding: 0 15px;
  }
  ::v-deep img.card-img-top {
    max-height: initial!important;
  }
  .HideMob {
    display: none;
  }
  .rMore {
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .MobWidth {
    padding: 0px;
  }
}
@media only screen and (min-width: 991px) {
  .HideDesk {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .row.HideDesk {
    margin-bottom: 50px;
  }
  .ourContainer.newspage {
    padding: 0px;
  }
  .late {
    font-weight: bold;
    font-size: 2rem;
    margin-top: -17px;
    width: 224px;
    padding-bottom: 10px;
  }
  ::v-deep  .ourContainer {
    max-width: none;
    margin: 0px auto;
    padding: 0 0px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .all>a{
    color: #009fe3;
    text-decoration: underline;
    font-weight: bold;
    font-size: 8pt;
  }
  .card-title {
    color: #201546;
    font-size: 15px;
    font-weight: bold;
  }
}
</style>
