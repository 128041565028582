<template>
  <div>
    <div id="content">
      <Splash
        :bannerImage="page[0].acf.banner_image"
      />
      <div class="ourContainer">
        <Route />
        <div class="ourContainer">
          <b-row>
            <b-col v-for="(pdf, index) in resourceFilter" :key='index' class="disCol" lg="4" md="12">
              <ol>
                <b-nav-item :href="pdf.acf.pdf_url">
                  <div>
                    <b-img
                      :src="pdf.acf.image"
                      :alt="pdf.acf.image"
                    ></b-img>
                    <br />
                    <div class="title" :class="Cat + 'Back'">
                      <span class="db">{{pdf.acf.title}}</span>
                    </div>
                  </div>
                </b-nav-item>
              </ol>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>   
    <Footer /> 
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
import Route from "@/components/route.vue";
import Splash from "@/components/splash.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "resources",
  components: { Route, Splash, Footer },
  data() {
    return {
      page: [],
      parentId: "",
      resources:[]
    };
  },
  computed: {
    Cat: function(cat, rn) {
      var sp = this.$route.name.split("-");
      if (sp.length > 2) {
        rn = [sp[0] + "-" + sp[1]];
      } else {
        rn = sp;
      }
      if (rn[0] === "news") {
        cat = "none";
      } else {
        cat = rn[0];
      }
      return cat;
    },
    resourceFilter:function(){
      return this.resources.filter((pdf) =>
          pdf.acf.category.includes(this.Cat));
    }
  },
  methods: {
    async Dis() {
      var rn = this.Cat;
      if (rn === "none") {
        apiCall
          .wpInfo("wp/v2/pages?slug=" + rn)
          .then(({ data }) => {
            this.page = data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            throw error;
          });
      } else {
        try {
          // First call, await for call to return
          let first_call = await apiCall.wpInfo("wp/v2/pages?slug=" + rn);
          this.parentId = first_call.data[0].id;
          this.loading = false;
          // Second call, await for call to return
          let second_call = await apiCall.wpInfo(
            "wp/v2/pages?slug=resources" + "&parent=" + this.parentId
          );
          this.page = second_call.data;
          this.loading = false;
          let third_call = await apiCall.wpInfo("wp/v2/pdfs");
          this.resources = third_call.data;
          this.loading = false;
        } catch (error) {
          console.log("error in await calls");
          console.log(error);
          throw error;
        }
      }
    },
  },
  mounted() {
    this.Dis();
  },
};
</script>

<style scoped>
#content {
  padding-left: 20%;
}
.disCol > .nav-item > .nav-link {
  padding: 0;
}
.disCol {
  padding: 0 5px 50px 5px;
}
.title {
  height: 66px;
  border-radius: 0px 33px 33px 0px;
  font-size: 18px;
  color: #fff;
  margin-top: -33px;
  position: absolute;
  padding: 20px;
  text-transform: uppercase;
  padding-right: 75px;
  -webkit-transition: padding-right 0.5s;
  transition: padding-right 0.5s;
}
.title:hover {
  padding-right: 140px;
}
.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.wp {
  padding-top: 50px;
}
.swimmingBack {
  background-color: #009fe3;
}
.para-swimmingBack {
  background-color: #afca0b;
}
.water-poloBack {
  background-color: #349980;
}
.mastersBack {
  background-color: #ff9901;
}
.artistic-swimmingBack {
  background-color: #f80240;
}
.divingBack {
  background-color: #6ec9df;
}
.open-waterBack {
  background-color: #5c439c;
}
@media only screen and (max-width: 990px) {
  #content {
    padding-left: 0;
  }
  ::v-deep .wp {
    padding-top: 10px!important;
  }
}
</style>
