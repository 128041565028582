<template>
  <div>
    <div id="content">
      <template v-if="$route.path === '/events/events-calendar' || $route.path === '/events/national-events'">
        <template v-if="$route.path === '/events/events-calendar'">
          <div data-v-a19df60e="" data-v-460d5b92="" style="position: relative;"><div data-v-a19df60e=""><div data-v-a19df60e="" class="overlay"><img data-v-a19df60e="" alt="imageOne" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/para-events.png" class="splash"></div></div><h1 data-v-a19df60e="" class="title"></h1></div>
        </template>
        <template v-else>
          <div data-v-a19df60e="" data-v-460d5b92="" style="position: relative;"><div data-v-a19df60e=""><div data-v-a19df60e="" class="overlay"><img data-v-a19df60e=""  alt="imageTwo" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/03/unnamed.jpg" class="splash"></div></div><h1 data-v-a19df60e="" class="title"></h1></div>
        </template>
      </template>
      <template v-else>
        <template v-if="$route.path === '/events/national-events'">
          <div >
            <Splash
              :bannerImage="page[6].acf.banner_image"
            />
          </div>
        </template>
        <template v-else-if="$route.path != '/events/events-calender'">
          <Splash
            :bannerImage="page[0].acf.banner_image"
          />
        </template>
      </template>
      <div class="ourContainer"> 
        <!-- <Route /> -->
        <div>
          <b-row>
            <b-col>
              <template v-if="$route.name === 'major-events'">
                <h2>
                  Major Events
                </h2>
              </template>
              <template v-else-if="$route.path === '/events/national-events'">
                <h2>
                  National Events
                </h2>
                
                <p v-if="$route.path !== '/events/national-events'" class="newsSection" v-html="page[2].content.rendered"></p>
              </template>
              <template v-else>
                <h2 v-if="$route.name === 'events-calendar'" style="text-transform: capitalize;">Events Calendar</h2>
                <h2 v-else style="text-transform: capitalize;">{{ routeTitle(Cat) }} Events</h2>
              </template>
              <template v-if="$route.name === 'events-calendar'">
                <b-row class="eventsCalandarBlock">
                  <!-- <a class="linkEvent" href="/events/events-calendar/aquatic-events">
                    <b-col>
                      <img class="imageSizeCalandar" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/01/snscsundayduncanscott5.jpg">
                      <div class="divBox">
                        <span class="eventsTitle">Aquatic Events</span>
                      </div>
                    </b-col>
                  </a> -->
                  <a class="linkEvent" href="/events/events-calendar/learning-and-development">
                    <b-col>
                      <img class="imageSizeCalandar" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/01/i0x0753.jpg">
                      <div class="divBox ">
                        <span class="eventsTitle">Learning and Dev...</span>
                      </div>
                    </b-col>
                  </a>
                  <a class="linkEvent" href="/events/events-calendar/networking-events">
                    <b-col>
                      <img class="imageSizeCalandar" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/01/snags-65-.jpg">
                      <div class="divBox">
                        <span class="eventsTitle">Networking Events</span>
                      </div>
                    </b-col>
                  </a>
                  <a class="linkEvent" href="/events/events-calendar/qualifications">
                    <b-col>
                      <img class="imageSizeCalandar" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/01/3-qualificiations-south-lanarkshire-1.jpg">
                      <div class="divBox">
                        <span class="eventsTitle">Qualificiations</span>
                      </div>
                    </b-col>
                  </a>
                </b-row>
              </template>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12" lg="4">
              <b-input-group class="mt-3">
                <b-form-input v-model="search" placeholder="Search" class="searchHeight"></b-form-input>
                <b-input-group-append>
                  <b-button
                    class="search"
                    :to="{
                      name: 'search',
                      query: {
                        id: search,
                      },
                    }"
                    ><font-awesome-icon class="icon" :icon="['fa', 'search']"
                  /></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <UpComing :discipline="Cat" total="all" />
        </div>
        <template v-if="$route.name === 'major-events'">
          <div class="nat">
            <b-row>
              <b-col>
                <h2>
                  National Events
                </h2>
              </b-col>
              <b-col>
                <b-input-group class="mt-3">
                  <b-form-select
                    v-model="pick"
                    placeholder="Search"
                    :options="options"
                  ></b-form-select>
                </b-input-group>
              </b-col>
            </b-row>
            <UpComing discipline="national-events" :pick="pick" total="all" />
          </div>
        </template>
      </div>
    </div> 
    <Footer />   
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
import UpComing from "@/components/upComing.vue";
// import Route from "@/components/route.vue";
import Splash from "@/components/splash.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "events",
  components: { 
    UpComing,
    Footer,
    // Route, 
    Splash 
  },
  data() {
    return {
      page: [],
      title: '',
      description: '',
      parentId: "",
      newsPage: false,
      pick: "",
      search:"",
      options: [
        { value: "", text: "All" },
        { value: "swimming", text: "Swimming" },
        { value: "para-swimming", text: "Para Swimming" },
        { value: "diving", text: "Diving" },
        { value: "open-water", text: "Open Water" },
        { value: "water-polo", text: "Water Polo" },
        { value: "masters", text: "Masters" },
        { value: "artistic-swimming", text: "Artistic Swimming" },
      ],
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  computed: {
    Cat: function(cat, rn) {
        var sp = this.$route.name.split("-");
        if (this.$route.name === "major-events" || this.$route.name === "national-events") {
          cat = this.$route.name;
        } else {
          if (sp.length > 2) {
            rn = [sp[0] + "-" + sp[1]];
          } else {
            rn = sp;
          }
          if (rn[0] === "news") {
            cat = "none";
          } else {
            cat = rn[0];
          }
      }
      return cat;
    },
    route: function() {
      var r = this.$route.fullPath.split("/");
      if (r[0] === "") {
        r[0] = "home";
      }
      return r;
    },
  },
  methods: {
    routeTitle(item) {
      var low = item;
      var rep = low.replace(/-/g, " ");
      return rep;
    },
    async Dis() {
      var rn = this.Cat;
      if (rn === "none" || rn === "national-events" || rn === "major-events") {
        apiCall
          .wpInfo("wp/v2/pages?slug=" + rn)
          .then(({ data }) => {
            this.page = data;
            this.title = data[0].title.rendered;
            this.description = data[0].yoast_head_json.og_description;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            throw error;
          });
      } else {
        try {
          // First call, await for call to return
          let first_call = await apiCall.wpInfo("wp/v2/pages?slug=" + rn);
          this.parentId = first_call.data[0].id;
          this.loading = false;
          // Second call, await for call to return
          let second_call = await apiCall.wpInfo(
            "wp/v2/pages?slug=events" + "&parent=" + this.parentId
          );
          this.page = second_call.data;
          this.title = second_call.data[0].title.rendered;
          this.description = second_call.data[0].yoast_head_json.og_description;
          this.loading = false;
        } catch (error) {
          console.log("error in await calls");
          console.log(error);
          throw error;
        }
      }
    },
  },
  mounted() {
    this.Dis();
  },
};
</script>

<style scoped>
.splash {
    height: 503px;
    -o-object-fit: cover;
    object-fit: cover;
}
a.linkEvent {
  text-decoration: none;
  width: 25%;
}
.eventsCalandarBlock {
  margin-bottom: 30px;
}
.divBox {
  background-color: #201546;
  height: 66px;
  border-radius: 0px 33px 0px 0px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-top: -66px;
  position: absolute;
  padding: 20px;
  text-transform: capitalize;
  padding-right: 30px;
  transition: padding-right 0.5s;
}
.divBox:hover:after {
  opacity: 1;
  right: 40px;
  position: absolute;
}
.divBox:hover {
  padding-right: 80px;
}
.divBox:after {
  content: '»';
  opacity: 0;  
}
/* h2.eventsTitle:hover {
  color: #201546;
  font-size: 16px;
  font-weight: bold;
  padding: 20px;
  text-decoration: none;
}
h2.eventsTitle {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 20px;
} */
img.imageSizeCalandar {
  width: 100%;
}
.searchHeight {
  height: 38px;
  background: #e8e7ec;
  border-radius: 0px;
}
#content {
  padding-left: 20%;
}

.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.nav-item > a {
  color: #009fe3;
}
.hide0 {
  display: none;
}
.nat {
  padding-top: 100px;
}
h2 {
  padding: 30px 0;
  color: #201546;
  font-weight: 500;
  font-family: GT-Haptik-Medium;
  font-size: 2.5rem;
}
.search {
  background-color: #e8e7ec;
  color: #201546;
  border: 1px solid lightgrey;
  /* border-radius: 0px 33px 33px 0px;
  width: 138px;
  height: 56px; */
}
.custom-select {
  height: 56px;
}
.icon {
  vertical-align: middle;
}
@media only screen and (max-width: 990px) {
  #content {
    padding-left: 0%;
  }
  a.linkEvent {
    text-decoration: none;
    width: 100%;
  }
  .ourContainer {
    max-width: none;
    margin: 0px auto;
    padding: 0 0px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media only screen and (max-width: 1200px) {
  a.linkEvent {
    text-decoration: none;
  }
  /* h2.eventsTitle {
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    padding: 20px;
  } */
}
</style>
