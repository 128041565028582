<template>
  <div class="pad"> 
    <b-row>
      <b-col
        class="disCol"
        lg="4"
        md="12"
        v-for="(dis, index) in volunteers.slice()"
        :key="index" 
      >
        <ol>
          <b-nav-item :to="'volunteers/' + dis.slug">
            <div>
              <b-img class="volImage" :src="dis.acf.image" :alt="dis.acf.image"></b-img>
              <br />
              <div
                class="title"
              >
                <span class="db" v-html="dis.acf.title"></span>
              </div>
            </div>
          </b-nav-item>
        </ol>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import { bus } from "../main.js";
export default {
  name: "Volunteers",
  data() {
    return {
      volunteers: [],
    };
  },
  methods: {
    setDis(picked) {
      this.pickDis = picked;
      bus.$emit("Picked", picked);
    },
  },
  mounted() {
    apiCall
      .wpInfo("wp/v2/volunteers?per_page=50")
      .then(({ data }) => {
        this.volunteers = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.featuredCols {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 50px!important;
  margin: auto;
}
img.rounded-0.eventImg.card-img {
  height: 260px;
  -o-object-fit: cover;
  object-fit: cover;
}
.card {
  background-color: #201546;
  color: #fff;
  border: 0px;
  border-radius: 0;
}
.datescard{
  font-size: 18px;
  font-weight: bold;
  padding: 20px 0;
  color: #009fe3;
}
.titlecard {
  font-size: 24px;
  font-weight: bold;
  padding: 20px 0;
}
.featuredNews {
  height: 280px;
  width: 100%;
}
.featBlock {
  border: 5px solid rgb(219, 4, 78);
}
.pad {
  padding: 50px 7px;
}
li {
  list-style: none;
}
.dis {
  padding: 5% 10%;
}
.disCol > ol > .nav-item > .nav-link {
  padding: 0;
}
ol {
  padding-left: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.disCol {
  padding: 0 5px 10px 5px;
}
.title {
  height: 66px;
  border-radius: 0px 33px 0px 0px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-top: -66px;
  position: absolute;
  padding: 20px;
  text-transform: capitalize;
  padding-right: 75px;
  transition: padding-right 0.5s;
  background-color: #1f1545;
}
.title:hover:after {
  opacity: 1;
  right: 40px;
  position: absolute;
}
.title:hover {
  padding-right: 140px;
}
.title:after {
  content: '»';
  opacity: 0;  
}
.mem{
  height: 275px;
}
@media only screen and (max-width: 500px) {
  .mem{
    height: auto;
  }
  .title {
    font-size: 14px;
  }
}
</style>
