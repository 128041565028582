<template>
  <div>
    <div v-if="$route.path.includes('learn-to-swim')" class="pad" :class="{notiles : this.mainMenu == '', withtiles : this.mainMenu.length > 0}"> 
      <b-row >
        <b-col
        class="disCol"
        lg="4"
        md="12"
        v-for="(dis, index) in mainMenu"
        :key="index"
        v-show="dis.slug !== 'stages-2' && dis.slug !== 'rewards-and-recognition' && dis.slug !== 'ambassadors' && dis.slug !== 'why-swim-2' && dis.slug !== 'club-finder' && dis.slug !== 'club-affiliation-guidelines' && dis.slug !== 'test' && dis.slug !== 'wellbeing-protection' && dis.slug !== 'volunteers' "
        >
          <template v-if="dis.acf.banner_image.length > 0">
            <ol>
              <b-nav-item class="backgroundImage" :to="$route.path + '/' + dis.slug" v-bind:style="{ backgroundImage: 'url(' + dis.acf.banner_image + ')' }">
                  <div>
                      <br />
                      <div class="Tiletitle" :class="{ orangeBack : $route.path.includes('/learn-to-swim') || $route.path.includes('/learn-to-swim//')  }">
                          <span class="db" v-html="dis.title.rendered"></span>
                      </div>
                  </div>
              </b-nav-item>
            </ol>
          </template>
          <template v-else>
            <ol>
              <b-nav-item class="backgroundImage" :to="$route.path + '/' + dis.slug" v-bind:style="{ backgroundImage: 'url(' + 'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/01/SS_Gradient.png' + ')' }">
                  <div>
                      <br />
                      <div class="Tiletitle" :class="{orangeBack : $route.path.includes('/learn-to-swim') || $route.path.includes('/learn-to-swim//')}">
                          <span class="db" v-html="dis.title.rendered"></span>
                      </div>
                  </div>
              </b-nav-item>
            </ol>
          </template>
        </b-col>
      </b-row>
    </div>
    <div v-else class="pad" :class="{notilesMain : this.mainMenu == ''}"> 
      <b-row>
        <b-col
        class="disCol"
        lg="4"
        md="12"
        v-for="(dis, index) in mainMenu"
        :key="index"
        v-show="dis.slug !== 'stages-2' && dis.slug !== 'rewards-and-recognition' && dis.slug !== 'ambassadors' && dis.slug !== 'why-swim-2' && dis.slug !== 'club-finder' && dis.slug !== 'club-affiliation-guidelines' && dis.slug !== 'test' && dis.slug !== 'wellbeing-protection' && dis.slug !== 'volunteers' "
        >
          <template v-if="dis.acf.banner_image.length > 0">
            <ol>
              <b-nav-item class="backgroundImage" :to="$route.path + '/' + dis.slug"  v-bind:style="{ backgroundImage: 'url(' + dis.acf.banner_image + ')' }">
                  <div>
                      <br />
                      <div class="Tiletitle">
                          <span class="db" v-html="dis.title.rendered"></span>
                      </div>
                  </div>
              </b-nav-item>
            </ol>
          </template>
          <template v-else>
            <template v-if="dis.acf.extralink_toggle == true">
              <ol>
                <b-nav-item class="backgroundImage" :href="dis.acf.extralink" target="_blank" v-bind:style="{ backgroundImage: 'url(' + 'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/01/SS_Gradient.png' + ')' }">
                    <div>
                        <br />
                        <div class="Tiletitle" >
                            <span class="db" v-html="dis.title.rendered"></span>
                        </div>
                    </div>
                </b-nav-item>
              </ol>
            </template>
            <template v-else>
              <ol>
                <b-nav-item class="backgroundImage" :to="$route.path + '/' + dis.slug"  v-bind:style="{ backgroundImage: 'url(' + 'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/01/SS_Gradient.png' + ')' }">
                    <div>
                        <br />
                        <div class="Tiletitle" >
                            <span class="db" v-html="dis.title.rendered"></span>
                        </div>
                    </div>
                </b-nav-item>
              </ol>
            </template>
          </template>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";

export default {
  name: "tileshow",
  props: ["slug"],
  data() {
    return {
      page: [],
      mainMenu: [],
    };
  },
  methods: {
    itemLink(item) {
      var low = item.toLowerCase();
      var rep = low.replace(/ /g, "-");
      return rep;
    },
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.wpInfo(
        "wp/v2/pages?slug=" + this.itemLink(this.$route.name)
      );
      this.page = first_call.data;
      this.pageid = first_call.data[0];
      this.loading = false;
      if (this.$route.path == '/events') {
        let third_call = await apiCall.wpInfo('wp/v2/pages?parent=' + '224'+ '&per_page=4');
        this.mainMenu = third_call.data;
        this.loading = false;
      } else {
        let third_call = await apiCall.wpInfo('wp/v2/pages?parent=' + this.pageid.id + '&per_page20'); 
        this.mainMenu = third_call.data;
        this.loading = false;
      }
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  }
};
</script>

<style scoped>
::v-deep li.nav-item.backgroundImage > a {
  list-style: none!important;
}
::v-deep li {
  list-style: none!important;
}
.withtiles {
  margin-top: 30px;
}
.notiles {
  margin-top: -30px;
}
.notilesMain {
  margin-top: -20px;
  padding: 0 !important;
}
.pad {
  padding: 25px;
  padding-bottom: 0px;
}
li.nav-item.backgroundImage {
  height: 250px;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center;
  background-size: cover;
}
.orangeBack {
  background-color: #fd6349!important;
}
.Tiletitle {
  height: 66px;
  border-radius: 0px 33px 0px 0px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-top: -66px;
  position: absolute;
  padding: 10px;
  text-transform: capitalize;
  padding-right: 75px;
  transition: padding-right 0.5s;
  background: #1b113e;
  bottom: 50px;
  width: 322px;
}
.Tiletitle:hover {
  padding-right: 140px;
}
.Tiletitle:hover:after {
  opacity: 1;
  right: 40px;
  position: absolute;
}
.Tiletitle:after {
  content: '»';
  opacity: 0;  
}
.disCol > ol > .nav-item > .nav-link {
  padding: 0;
}
.disCol > ol  {
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 30px;
}
.disCol {
  padding: 0 5px 50px 5px;
}
::v-deep .hidedesk > span.noexpand > p:last-child {
  opacity: .3;
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}
::v-deep .hidedesk > span.expand > p:last-child {
  opacity: 1;
}
.readMoreContent > .wpContent {
  color: #707070;
}
button.btn.readMoreBtn.btn-primary.not-collapsed {
  display: none;
}
button.btn.readLessBtn.btn-primary.collapsed {
  display: none;
}
.wpcontentHeight {
  /* margin-top: 30px!important; */
  display: block;
}
.readMoreBtn {
  background-color: #201646!important;
  margin-top: 25px;
  margin-bottom: 25px;
  border-color: white;
}
.readLessBtn {
  background-color: #201646!important;
  margin-top: 25px;
  margin-bottom: 25px;
  border-color: white;
}
::v-dee .html5-endscreen.ytp-player-content.videowall-endscreen.ytp-endscreen-paginate.ytp-show-tiles {
  display: none!important;
}
::v-deep iframe {
  width: 100%;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #fff;
  border-color: #545b62;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}
button.print:hover {
  background-color: #009fe3;
  color: #fff;
  border: #201546;
  padding: 10px;
  border-radius: 2px;
  margin-left: 8px;
}
button.print {
  background-color: #201546;
  color: #fff;
  border: #201546;
  padding: 10px;
  border-radius: 2px;
  margin-left: 8px;
  display: none;
}
iframe.iframeBoarder {
  width: 100%;
  height: 1200px;
}
#content {
  padding-left: 20%;
}
.overlay {
  background-color: #201546;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/HomePage_Overlay_1@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.splash {
  opacity: 0.46;
  height: 603px;
  object-fit: cover;
}
.title {
  position: absolute;
  top: 277px;
  width: 100%;
  text-align: center;
  height: 75px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000058;
  font-weight: bold;
  font-size: 3.5rem;
}
.route {
  display: inline-flex;
}
.route > li > a {
  padding: 0.5rem 0;
  text-transform: uppercase;
  font-weight: bold;
}
.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.wp {
  padding-top: 50px;
}
h2 {
  padding: 50px 0 0 0;
  color: #201546;
  margin-bottom: -10px;
}
h4 {
  padding: 50px 0 0 0;
  color: #201546;
  margin-bottom: -10px;
}
::v-deep .wpContent > h2 {
  padding: 30px 0;
  color: #201546;
  font-weight: bolder;
  font-size: 24px;
  font-weight: 500;
}
::v-deep .wpContent > h2 > strong{
  font-weight: 500!important;
}
::v-deep .wpContent > p {
  color: #707070;
  font-size: 16px;
}
::v-deep .wpContent > figure {
  padding-top: 20px;
}
::v-deep img {
  object-fit: contain;
}
.faqQ {
  background-color: transparent;
  color: #009fe3!important;
  font-style: italic;
  border: 0;
  text-align: left;
}
.DropBut {
  background-color: white;
  color: #009fe3;
  font-style: italic;
  border: 0;
  text-align: left;
}
.accordion > .card.drop {
  border-top: 1px solid!important;
  border:none;
  border-radius: 0px;
}
.accordion > .card.drop > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
  border: 0px;
  box-shadow: 0px;
  padding: 0px!important;
}
.arrow {
  border: solid #009fe3;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  right: 10px;
  top: 15px;
}

.nav-item > a {
  color: #009fe3;
}
::v-deep .wpContent > ul > li {
  list-style-type: disc;
  color: #707070;
}
.hide0 {
  display: none;
}
.sideTitle {
  color: #201546;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
}
.route > li:first-child > a {
  text-decoration: underline;
}
.route > li:nth-child(2) > a {
  text-decoration: underline;
}
::v-deep .wpContent > p > a {
  color: #009fe3;
}
::v-deep table {
  width: 100%;
}
::v-deep th {
  background: #009fe3 0% 0% no-repeat padding-box;
  color: #fff;
  padding: 20px 16px 19px 21px;
}
::v-deep td {
  border: 4px solid #fff;
  padding: 17px 18px 17px 21px;
  font-size: 14px;
  color: #707070;
  width: 7%;
}
::v-deep .wpContent > ul > li > a {
  color: #000;
}
::v-deep tr {
  background-color: #f7f7f7;
}
.top {
  padding-top: 50px;
}
.bottom {
  padding-bottom: 30px;
}
::v-deep a {
  color: #009fe3;
  text-decoration: none;
  background-color: transparent;
}
@media only screen and (min-width: 991px) {
  ::v-deep .hidedesk {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  ::v-deep .hidemob {
    display: none;
  }
  ::v-deep img {
    -o-object-fit: contain;
    object-fit: contain;
    height: auto;
  }
  ::v-deep img.wp-image-8616 {
    object-fit: contain!important;
    height: auto;
  }
  #content {
    padding-left: 0;
  }
  ::v-deep .wp {
    padding-top: 10px !important;
  }
  ::v-deep iframe {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep .wpContent > figure {
    padding-top: 20px;
    overflow: scroll;
  }
  ::v-deep .pad {
    padding: 25px;
    padding-bottom: 0px;
    padding-left: 12px;
    padding-right: 12px;
  }
}
</style>