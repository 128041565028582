<template>
  <div>
    <div id="content">
      <div class="ourContainer">
        <!-- <Route /> -->
        <div class="wp">
          <b-row>
            <b-col lg="3" md="3" cols="12" class="disCol">
              <b-img :alt="page[0].acf.image" :src="page[0].acf.image"></b-img>
            </b-col>
            <b-col>
              <p class="standard">
                {{ dateTime(page[0].date) }}
              </p>
              <p class="title">
                {{ page[0].acf.title }}
              </p>
              <p class="specItem">
                <span class="specTitle">Organisation: </span
                ><span class="standard">{{ page[0].acf.organisation }}</span>
              </p>
              <p class="specItem">
                <span class="specTitle">Location: </span
                ><span class="standard">{{ page[0].acf.location }}</span>
              </p>
              <p class="specItem">
                <span class="specTitle">Job Type: </span
                ><span class="standard">{{ page[0].acf.job_type }}</span>
              </p>
              <p class="specItem">
                <span class="specTitle">Hours: </span
                ><span class="standard">{{ page[0].acf.hours }}</span>
              </p>
              <p class="specItem">
                <span class="specTitle">Salary: </span
                ><span class="standard">{{ page[0].acf.salary }}</span>
              </p>
              <p class="specItem">
                <span class="specTitle">Closing Date: </span
                ><span class="standard">{{ page[0].acf.closing_date }}</span>
              </p>
            </b-col>
            <b-col lg="2" md="2" cols="12">
                <b-button v-if="page[0].acf.contact === ''" :href="page[0].acf.job_link" class="apply"
                >Apply</b-button
              >
              <b-button v-else :href="'mailto:' + page[0].acf.contact" class="apply"
                >Apply</b-button
              >
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col
              :class="{ 'col-lg-9 col-md-9': page[0].acf.right_sidebar === true }"
            >
              <!-- This is Wordpress rendered content for the page -->
              <span class="wpContent" v-html="page[0].content.rendered"></span>
              <!-- End is Wordpress rendered content for the page -->
            </b-col>
            <!-- Right Side Bar -->
            <template v-if="page[0].acf.right_sidebar === true">
              <b-col>
                <!-- Ads -->
                <template v-if="page[0].acf.ad_links.length">
                  <SideBarBlock
                    v-for="(link, index) in page[0].acf.ad_links"
                    :key="index"
                    type="ad"
                    :image="link.image"
                    :url="link.url"
                  />
                </template>
                <!-- End Ads -->

                <!-- Downloads -->
                <template v-if="$route.path === '/our-sports/swimming/records'">
                  <template v-if="page[1].acf.downloads.length">
                    <div class="downloadsLink">
                      <h1 class="sideTitle">
                        <template v-if="page[1].acf.downloads_header_text == true ">
                          <h1 class="sideTitle downTop">
                            {{page[1].acf.downloads_header_text}}
                            <hr class="hrLine">
                          </h1>
                        </template>
                        <template v-else>
                          <h1 class="sideTitle downTop">
                            Downloads
                            <hr class="hrLine">
                          </h1>
                        </template>
                      </h1>
                      <SideBarBlock
                        v-for="(link, index) in page[1].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>
                <template v-else-if="$route.path === '/our-sports/para-swimming/records'">
                  <template v-if="page[3].acf.downloads.length">
                    <div class="downloadsLink">
                      <template v-if="page[3].acf.downloads_header_text == true ">
                        <h1 class="sideTitle downTop">
                          {{page[3].acf.downloads_header_text}}
                          <hr class="hrLine">
                        </h1>
                      </template>
                      <template v-else>
                        <h1 class="sideTitle downTop">
                          Downloads
                          <hr class="hrLine">
                        </h1>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[3].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>
                <template v-else-if="$route.path === '/our-sports/masters/records'">
                  <template v-if="page[2].acf.downloads.length">
                    <div class="downloadsLink">
                      <template v-if="page[2].acf.downloads_header_text == true ">
                        <h1 class="sideTitle downTop">
                          {{page[2].acf.downloads_header_text}}
                          <hr class="hrLine">
                        </h1>
                      </template>
                      <template v-else>
                        <h1 class="sideTitle downTop">
                          Downloads
                          <hr class="hrLine">
                        </h1>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[2].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>
                <template v-else>
                  <template v-if="page[0].acf.downloads.length">
                    <div class="downloadsLink">
                      <template v-if="page[0].acf.downloads_header_text == true ">
                        <h1 class="sideTitle downTop">
                          {{page[0].acf.downloads_header_text}}
                          <hr class="hrLine">
                        </h1>
                      </template>
                      <template v-else>
                        <h1 class="sideTitle downTop"> Downloads 
                          <hr class="hrLine">
                        </h1>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[0].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>                  

                <!--End Downloads -->

                <!-- URL Links -->
                <template v-if="page[0].acf.useful_links.length">
                  <div class="usefulLinks">
                    <template v-if="page[0].acf.usefull_links_text == true">
                      <h1 class="sideTitle top">
                        {{page[0].acf.usefull_links_text}}
                        <hr class="hrLine">
                      </h1>
                    </template>
                    <template v-else>
                      <h1 class="sideTitle top">
                        Useful Links
                        <hr class="hrLine">
                      </h1>
                    </template>
                    <SideBarBlock
                      v-for="(link, index) in page[0].acf.useful_links"
                      :key="index"
                      type="link"
                      :title="link.title"
                      :url="link.url"
                    />
                  </div>
                </template>                  

                <!-- End URL Links -->
              </b-col>
            </template>
            <!-- End Right Side Bar -->
          </b-row>
        </div>
      </div>
    </div> 
    <Footer />   
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
// import Route from "@/components/route.vue";
import SideBarBlock from "@/components/sidebarBlock.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "jobPage",
  props: ["id"],
  components: { 
    // Route,
    Footer,
    SideBarBlock
  },
  data() {
    return {
      page: [],
      title: '',
      description: '',
    };
  },
  metaInfo() {
    return {
      title: 'Jobs-' + this.page[0].acf.title,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  methods: {
    itemLink(item) {
      var low = item.toLowerCase();
      var rep = low.replace(/ /g, "-");
      return rep;
    },
    dateTime(value) {
      var dateOG = value.split("T");
      var splitDate = dateOG[0].split("-");
      var newDate = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0];
      return newDate;
    },
  },

  mounted() {
    apiCall
      .wpInfo("wp/v2/jobs?slug=" + this.id)
      .then(({ data }) => {
        this.page = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
li.nav-item.linkText {
  line-height: 18px;
}
::v-deep .sideTitle {
  color: #201546;
  font-size: 17px;
  font-weight: 100;
  padding-bottom: 20px;
  padding: 15px 0px 20px 0;
  padding-top: 20px;
  font-family: GT-Haptik-Medium;
  margin-bottom: -15px;
}
hr.hrLine {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  border-top: 1px solid #1f1545;
}
h1.sideTitle.downTop {
  margin-top: -10px;
}
.downloadsLink {
  background-color: #cbebf9;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  border-radius: 5px;
  padding-right: 10px;
  margin-top: 10px;
}
.usefulLinks {
  background-color: #cbebf9;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
  margin-top: 10px;
  border-radius: 5px;
  padding-right: 10px;
}
#content {
  padding-left: 20%;
}

.route {
  display: inline-flex;
}
.route > li > a {
  padding: 0.5rem 0;
  text-transform: uppercase;
  font-weight: bold;
}
.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.wp {
  padding-top: 50px;
}
h2 {
  padding: 50px 0 0 0;
  color: #201546;
  margin-bottom: -10px;
}
::v-deep .wpContent > h2 {
  padding: 30px 0;
  color: #201546;
  font-size: 24px;
  font-weight: 500;
}
::v-deep .wpContent > h2 > strong{
  font-weight: 500!important;
}
::v-deep .wpContent > p {
  color: #707070;
  font-size: 16px;
}
::v-deep .wpContent > figure {
  padding-top: 20px;
}
::v-deep img {
  object-fit: cover;
}

.nav-item > a {
  color: #009fe3;
}
::v-deep .wpContent > ul > li {
  list-style-type: disc;
  color: #707070;
}

::v-deep .wpContent > p > a {
  color: #009fe3;
}
::v-deep table {
  width: 100%;
}
::v-deep th {
  background: #009fe3 0% 0% no-repeat padding-box;
  color: #fff;
  padding: 20px 16px 19px 21px;
}
::v-deep td {
  border: 4px solid #fff;
  padding: 17px 18px 17px 21px;
  font-size: 14px;
  color: #707070;
  width: 7%;
}
::v-deep .wpContent > ul > li > a {
  color: #000;
}
::v-deep tr {
  background-color: #f7f7f7;
}
.top {
  padding-top: 30px;
}
.bottom {
  padding-bottom: 30px;
}
.apply{
  display: inline-block;
  padding: 0.3333em 0.8333em;
  background: #201646;
  color: #ffffff;
  min-height: 34px;
  border-radius: 0;
}
.standard{
  font-size: 14px;
}
.title{
  font-size: 1.3889em;
  margin-bottom: 12px;
  color: #201546;
  font-weight: bold;
}
.specTitle{
  color: #201546;
  font-weight: bold;
}
.specItem{
  margin-bottom: .5rem;
}
@media only screen and (max-width: 990px) {
  #content {
    padding-left: 0;
  }
  ::v-deep .wp {
    padding-top: 10px!important;
  }
  .apply{
    display: inline-block;
    padding: 0.3333em 0.8333em;
    background: #201646;
    color: #ffffff;
    min-height: 34px;
    border-radius: 15px;
    width: 100%;
    margin-top: 30px;
  }
  .title {
    font-size: 1.3889em;
    margin-bottom: 12px;
    color: #201546;
    font-weight: bold;
    margin-bottom: 30px;
  }
}
</style>
