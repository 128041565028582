<template>
  <div>
    <div id="content">
      <div class="top">
        <b-row>
          <b-col cols="8" class="mobSizeTitle">
            <div class="info">
              <b-row>
                <b-col>
                  <h1 class="name">{{ profile[0].acf.title }}</h1>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p class="subT">
                    Job Role
                  </p>
                  <p class="value" :class="dis">
                    {{ profile[0].acf.job_role }}
                  </p>
                </b-col>
                <b-col>
                  <p class="subT">
                    Telephone
                  </p>
                  <p class="value" :class="dis">
                    {{ profile[0].acf.telephone }}
                  </p>
                </b-col>
                <b-col>
                  <p class="subT">
                    Email
                  </p>
                  <p class="value" :class="dis">
                    <a style="color:#fff" :href="'mailto:'+profile[0].acf.email">{{ profile[0].acf.email }}</a>
                  </p>
                </b-col>
              </b-row>
              <div class="line"></div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="ourContainer">
      <!-- <Route /> -->
        <div class="wp">
          <b-row class="HideMob">
            <b-col cols='8'>
              <span class="wpContent" v-html="profile[0].content.rendered"></span>
            </b-col>
            <b-col cols='4'>
                <b-img :alt="profile[0].acf.image" class="teamImage" :src="profile[0].acf.image"></b-img>     
            </b-col>
          </b-row>
          <b-row class="HideDesk">
            <b-col>
              <b-img :alt="profile[0].acf.image" class="mobImage" :src="profile[0].acf.image"></b-img>     
              <span class="wpContent" v-html="profile[0].content.rendered"></span>
            </b-col>
          </b-row>
        </div>
      </div>
    </div> 
    <Footer />   
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
// import Route from '@/components/route.vue'
import Footer from '@/components/footer.vue'
export default {
  name: "profilePage",
  components:{
    // Route, 
    Footer
  },
  props: ["slug", "dis"],
  data() {
    return {
      profile: [],
      title: '',
    };
  },
  metaInfo() {
    return {
      title: this.title
    }
  },
  mounted() {
    apiCall
      .wpInfo("wp/v2/meet-the-team?slug=" + this.slug)
      .then(({ data }) => {
        this.profile = data;
        this.title = data[0].title.rendered;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
#content {
  padding-left: 20%;
}
.top {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Main_Profile_Background@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 80px;
}
.route {
  display: inline-flex;
}
.route > li > a {
  padding: 0.5rem 0;
  text-transform: uppercase;
  font-weight: bold;
}
.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.circle {
  width: 30px;
  height: 30px;
  line-height: 22px;
  border-radius: 50%;
  font-size: 18px;
  color: #fff;
  text-align: center;
  background: transparent;
  margin-left: 5px;
  margin-right: 5px;
}
.gold {
  border: solid 4px #ad883b;
  color: #ad883b;
}
.silver {
  border: solid 4px #8d8d8d;
  color: #8d8d8d;
}
.bronze {
  border: solid 4px #9d5f0b;
  color: #9d5f0b;
}
.medals {
  display: inline-flex;
}
.name {
  color: #fff;
  font-size: 70px;
  text-transform: capitalize;
  padding-bottom: 71px;
  font-weight: bold;
}
.subT {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}
.value {
  color: #fff;
  font-size: 22px;
}
.swimming {
  color: #009fe3;
}
.para-swimming {
  color: #afca0b;
}
.water-polo {
  color: #349980;
}
.masters {
  color: #ff9901;
}
.artistic-swimming {
  color: #f80240;
}
.diving {
  color: #6ec9df;
}
.open-water {
  color: #5c439c;
}
.info {
  margin-top: 120px;
  margin-left: 100px;
}
.line {
  border: 5px solid #ffffff;
  opacity: 0.05;
  margin-bottom: 39.5px;
}
.icon {
  width: 36px;
  margin-right: 10px;
}
::v-deep table {
  width: 100%;
}
::v-deep th {
  background: #009fe3 0% 0% no-repeat padding-box;
  color: #fff;
  padding: 20px 16px 19px 21px;
}
::v-deep td {
  border: 4px solid #fff;
  padding: 17px 18px 17px 21px;
  font-size: 14px;
  color: #707070;
  width: 7%;
} 
::v-deep .wpContent > ul >li >a{
  color: #009fe3;
}
::v-deep tr {
  background-color: #F7F7F7;
}
::v-deep .wpContent > p:first-child {
  color: #1f1545;
  font-weight: bold;
}
.wp {
  padding-top: 50px;
}
::v-deep .wp-block-image > img {
  object-fit: cover;
}
.hide0{
  display: none;
}
@media only screen and (min-width: 991px) {
  .HideDesk {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
    .mobImage {
      margin-bottom: 50px;
    }
    .HideMob {
      display: none;
    }
    .subT {
      font-size: 11px;
    }
    .value {
      color: #fff;
      font-size: 14px;
    }
    .name {
      color: #fff;
      font-size: 25px;
      text-transform: uppercase;
      padding-bottom: 71px;
      font-weight: bold;
    }
    img.teamImage {
        display: none;
    }
    #content {
        padding-left: 0%;
    }
    .mobSizeTitle.col-8 {
        width: 100%;
        flex: 100%;
        max-width: 100%;
    }
    .info {
        margin-top: 120px;
        margin-left: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
}
</style>
