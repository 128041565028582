<template>
  <div>
    <div id="content">
      <!-- <Splash
        :title="this.page[0].title.rendered"
        :bannerImage="page[0].acf.banner_image"
      /> -->
      <div class="ourContainer">
        <!-- <Route /> -->
        <div class="wp">
          <b-row class="ProfileInformation">
            <b-col cols="12" lg="6" md="6">
              <h1 class="head">{{ routeTitle(Cat) }} Profiles</h1>
              <p v-html="page[0].acf.profile_para"></p>
              <!-- <b-input-group class="mt-3 inputDropdown">
                <b-form-select v-model="squad" :options="options"></b-form-select>
              </b-input-group> -->
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="disCol"
              lg="12"
              md="12"
              cols="12"
              v-for="(pro, index) in disProfiles"
              :key="index">
              <b-card class="">
                <ol>
                <b-nav-item :to="'/our-sports/' + Cat + '/profiles/' + pro.slug">
                  <b-row class="mobileBlock">
                    <b-col>
                      <div class="proImageback" v-if="pro.acf.image.length > 0">
                        <img :alt="pro.acf.image" class="proImage" :src="pro.acf.image">
                      </div>
                      <div class="proImageback" v-else>
                        <img class="proImageDummy" :alt="'dummyImage'" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/05/966-9665317_placeholder-image-person_2jpg.jpg">
                      </div>
                    </b-col>
                    <b-col class="lineDetails">
                      <h1 class="name">{{ pro.acf.title }}</h1>
                      <p class="represents">{{ pro.acf.represents }}</p>
                    </b-col>
                    <b-col class="lineDetailsMedals">
                      <div class="medals">
                        <div class="circle gold">
                          <template v-if="pro.acf.gold !== ''">
                            {{ pro.acf.gold }}
                          </template>
                          <template v-else>
                            0
                          </template>
                        </div>
                        <div class="circle silver">
                          <template v-if="pro.acf.silver !== ''">
                            {{ pro.acf.silver }}
                          </template>
                          <template v-else>
                            0
                          </template>
                        </div>
                        <div class="circle bronze">
                          <template v-if="pro.acf.bronze !== ''">
                            {{ pro.acf.bronze }}
                          </template>
                          <template v-else>
                            0
                          </template>
                        </div>
                      </div>
                    </b-col>
                    <b-col class="lineDetails plusOpen">
                      <div class="openPlusIcon">
                        <span class="OpenText">
                          Open
                        </span>
                        <span class="plusIcon">
                          <font-awesome-icon class="icon" :icon="['fa', 'plus']"/>
                        </span>
                      </div>
                    </b-col>
                  </b-row>
                </b-nav-item>
                </ol>
              </b-card>
            </b-col>
          </b-row>


          <!-- <b-row>
            <b-col
              class="disCol"
              lg="6"
              md="12"
              cols="12"
              v-for="(pro, index) in disProfiles"
              :key="index"
            >
              <b-nav-item :to="'/our-sports/' + Cat + '/profiles/' + pro.slug">
                <div class="pro">
                  <b-row>
                    <b-col lg="7" md="7" cols="7">
                      <div class="proInfo">
                        <h2 class="rep" :class="pro.acf.discipline[0]">
                          {{ pro.acf.represents }}
                        </h2>
                        <h2 class="name">{{ pro.acf.title }}</h2>
                        <div class="medals">
                          <div class="circle gold">{{ pro.acf.gold }}</div>
                          <div class="circle silver">{{ pro.acf.silver }}</div>
                          <div class="circle bronze">{{ pro.acf.bronze }}</div>
                        </div>
                      </div>
                    </b-col>
                    <b-col lg="5" md="5" cols="5">
                      <div class="imageBlock">
                        <b-img v-if="pro.acf.image.length > 0" class="proImg" :src="pro.acf.image"></b-img>
                        <b-img v-else></b-img>
                      </div>
                    </b-col>
                  </b-row>

                  <div class="titleBtn" :class="pro.acf.discipline[0] + 'Back'">
                    <b-nav-item
                      :to="'/our-sports/' + Cat + '/profiles/' + pro.slug"
                    >
                      <span class="db">FIND OUT MORE</span>
                    </b-nav-item>
                  </div>
                </div>
              </b-nav-item>
            </b-col>
          </b-row> -->
        </div>
      </div>
    </div>  
    <Footer />  
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
// import Route from "@/components/route.vue";
// import Splash from "@/components/splash.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "profiles",
  components: { 
    Footer
    // Route, 
    // Splash 
  },
  data() {
    return {
      page: [],
      title: '',
      description: '',
      parentId: "",
      profiles: [],
      squad: "All",
      options: [
        { value: "All", text: "All" },
        { value: "gold", text: "National Senior Gold Squad" },
        { value: "silver", text: "National Senior Silver Squad" },
        { value: "youth", text: "National Youth Squad" },
        { value: "district", text: "District Regional Programme" },
      ],
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  computed: {
    Cat: function(cat, rn) {
      var sp = this.$route.name.split("-");
      if (sp.length > 2) {
        rn = [sp[0] + "-" + sp[1]];
      } else {
        rn = sp;
      }
      if (rn[0] === "news") {
        cat = "none";
      } else {
        cat = rn[0];
      }
      return cat;
    },
    disProfiles: function(filt) {
      if (this.squad === "All") {
        filt = this.profiles.filter(
          (dis) => dis.acf.discipline[0] === this.Cat
        );
      } else {
        filt = this.profiles.filter(
          (dis) =>
            dis.acf.discipline[0] === this.Cat && dis.acf.squad[0] === this.squad
        );
      }
      return filt;
    },
  },
  methods: {
    routeTitle(item) {
      var low = item;
      var rep = low.replace(/-/g, " ");
      return rep;
    },
    async Dis() {
      var rn = this.Cat;
      try {
        // First call, await for call to return
        let first_call = await apiCall.wpInfo("wp/v2/pages?slug=" + rn);
        this.parentId = first_call.data[0].id;
        this.loading = false;
        // Second call, await for call to return
        let second_call = await apiCall.wpInfo(
          "wp/v2/pages?slug=profiles" + "&parent=" + this.parentId
        );
        this.page = second_call.data;
        this.title = second_call.data[0].title.rendered;
        this.description = second_call.data[0].yoast_head_json.og_description;
        this.loading = false;
        let third_call = await apiCall.wpInfo("wp/v2/profiles?per_page=100");
        this.profiles = third_call.data;
        this.loading = false;
      } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
      }
    },
  },
  mounted() {
    this.Dis();
  },
};
</script>

<style scoped>
p.represents {
  font-size: 10pt;
  color: #201546;
  text-transform: uppercase;
  margin-top: -15px;
}
.OpenText {
  margin-right: 15px;
}
.openPlusIcon {
  text-align: right;
}
.plusOpen {
  color: #000;
  font-weight: 600;
  font-size: 13pt;
}
.lineDetails {
  position: relative;
  top: 25px;
  text-align: left;
}
.lineDetailsMedals {
  position: relative;
  top: 25px;
  text-align: center;
}
.proImageback {
  height: 100px;
  width: 100px;
  background: #fff;
  border-radius: 100px;
  overflow: hidden;
  margin-top: -15px;
}
.proImageDummy {
  margin-top: 18px;
}
.card {
  background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/SwimmingProfilesBG.png');
  color: #201546;
  border: none!important;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 15px;
}
.card:hover {
  background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/SwimmingProfilesBGHover.png');
  background-repeat: no-repeat;
  background-size: cover;
}
span.arrow {
  float: right;
  display: grid;
  text-align: center;
}
.ProfileInformation {
  margin-bottom: 30px;
}
img.proImg.MS {
  margin-top: 83px;
}
#content {
  padding-left: 20%;
}
.overlay {
  background-color: #201546;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/HomePage_Overlay_1@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.splash {
  opacity: 0.46;
  height: 603px;
  object-fit: cover;
}
.title {
  position: absolute;
  top: 277px;
  width: 100%;
  text-align: center;
  height: 75px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000058;
  font-weight: bold;
  font-size: 3.5rem;
}
.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.wp {
  padding-top: 50px;
}
.titleBtn {
  height: 66px;
  width: 240px;
  border-radius: 0px 33px 33px 0px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-top: -33px;
  position: absolute;
  padding: 20px;
  text-transform: uppercase;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  position: absolute;
  bottom: 0;
}
.titleBtn:hover {
  font-weight: bold;
  width: 335px;
}
.titleBtn > .nav-item > a {
  padding: 0;
  color: #fff;
}
.pro {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Profile_BG.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 260px;
  margin-bottom: 30px;
}
.circle {
  width: 40px;
  height: 40px;
  /* line-height: 22px; */
  line-height: 30px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;
  color: #201546;
  text-align: center;
  background: transparent;
  margin-left: 5px;
  margin-right: 5px;
}
.gold {
  border: solid 4px #ad883b;
  color: #ad883b;
}
.silver {
  border: solid 4px #8d8d8d;
  color: #8d8d8d;
}
.bronze {
  border: solid 4px #9d5f0b;
  color: #9d5f0b;
}
.medals {
  display: inline-flex;
  padding-bottom: 20px;
}

.name {
  font-size: 30px;
  color: #201546ff;
  padding-bottom: 20px;
}
.rep {
  font-size: 20px;
  padding-bottom: 10px;
}
.swimming {
  color: #009fe3;
}
.para-swimming {
  color: #afca0b;
}
.water-polo {
  color: #349980;
}
.masters {
  color: #ff9901;
}
.artistic-swimming {
  color: #f80240;
}
.diving {
  color: #6ec9df;
}
.open-water {
  color: #5c439c;
}
.swimmingBack {
  background-color: #009fe3;
}
.para-swimmingBack {
  background-color: #afca0b;
}
.water-poloBack {
  background-color: #349980;
}
.mastersBack {
  background-color: #ff9901;
}
.artistic-swimmingBack {
  background-color: #f80240;
}
.divingBack {
  background-color: #6ec9df;
}
.open-waterBack {
  background-color: #5c439c;
}
.proInfo {
  padding: 40px;
}
.nav-item > a {
  color: #009fe3;
}
.head {
  padding-bottom: 40px;
  color: #201546;
  text-transform: capitalize;
  /* font-weight: bold; */
  font-family: GT-Haptik-Medium;
  font-size: 2.5rem;
}
/* .proImg {
  position: absolute;
  bottom: 0;
  right: 15px;
} */
.proImg {
  bottom: -28px;
  display: inline-block;
  float: right;
  /* margin-top: 50px; */
  position: relative;
}
@media only screen and (max-width: 1440px) {
  .lineDetails{
    top: 17px;
  }
  .lineDetailsMedals{
    top: 17px;
  }
  .circle {
    width: 30px;
    height: 30px;
    line-height: 22px;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
  color: #201546;
    text-align: center;
    background: transparent;
    margin-left: 5px;
    margin-right: 5px;
  }
  .proImg {
    bottom: -69px;
    display: inline-block;
    float: right;
    /* margin-top: 50px; */
    position: relative;
  }
  .proImageback {
    height: 80px;
    width: 80px;
    background: #fff;
    border-radius: 100px;
    overflow: hidden;
    margin-top: -11px;
  }
  .name {
    font-size: 23px;
    color: #201546ff;
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 1366px) {
  .proImg {
    bottom: -81px;
    display: inline-block;
    float: right;
    /* margin-top: 50px; */
    position: relative;
  }
}
@media only screen and (max-width: 1280px) {
  .proImg {
    bottom: 0;
    height: 100px;
    width: 150px;
    display: inline-block;
    float: right;
    margin-top: 160px;
  }
}
@media only screen and (max-width: 990px) {
  .pro {
    height: 180px;
  }
  .nav-item > a {
    color: #009fe3;
    padding: 1rem 0rem;
  }
  #content {
    padding-left: 0%;
  }
  .disCol {
    padding-bottom: 40px;
  }
  ::v-deep .wp {
    padding-top: 10px!important;
  }
  ::v-deep .proImg {
    width: 100%;
    bottom: 0;
    height: 150px;
    width: 150px;
    display: inline-block;
    float: right;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .head {
    padding-bottom: 10px;
    color: #201546;
    text-transform: capitalize;
  }
  .inputDropdown {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 500px) {
  .card {
    background-size: cover;
  }
  .card:hover {
    background-size: cover;
  }
  .plusOpen {
    display: none;
  }
  .lineDetails {
    text-align: center;
  }
  .mobileBlock {
    display: inline-flex;
  }
  .proImageback {
    height: 100px;
    width: 100px;
    background: #fff;
    border-radius: 100px;
    overflow: hidden;
    margin: auto;
    margin-top: -5px;
  }
  .name {
    font-size: 20px;
    color: #201546ff;
    padding-bottom: 20px;
    text-align: left;
  }
  p.represents {
    font-size: 10pt;
    color: #201546;
    text-transform: uppercase;
    margin-top: -15px;
    text-align: left;
  }
  .lineDetailsMedals {
    position: relative;
    top: 15px;
    text-align: center;
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .proImg {
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 16px;
  }
  .medals {
    display: none;
  }
  .proInfo {
    padding: 10px;
  }
}
</style>
