<template>
  <div>
    <div id="content" class="contentBlock">
      <Splash
        :bannerImage="page[0].acf.banner_image"
      />
      <div class="ourContainer">
          <h1 class="whoops">Whoops!</h1>
          <p class="pageNotFound">404 Page Not Found</p>
          <p class="didYouMean">
            Did you mean 
            <b-link @click="goSearch()" v-model="searchMeaning" class="searchMean">
              {{searchMeaning}} ?
            </b-link>
          </p>
      </div>
    </div>   
    <Footer /> 
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
import Splash from "@/components/splash.vue";
import Footer from '@/components/footer.vue';

export default {
  name: "mainPagesNew",
  props: ["slug"],
  components: { 
    Splash, 
    Footer 
  },
  data() {
    return {
      searchMeaning: this.$route.params.slug,
      page: [],
      search:'',
    };
  },
  methods: {
    itemLink(item) {
      var low = item.toLowerCase();
      var rep = low.replace(/ /g, "-");
      return rep;
    },
    goSearch() {
      this.$router.push({ name: "search", query: { search:  this.$route.params.slug } });
    },
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.wpInfo("wp/v2/pages?slug=" + this.itemLink(this.$route.name))
      this.page = first_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
};
</script>

<style scoped>
p.didYouMean {
  text-align: center;
  font-weight: bold;
  font-family: GT-Haptik-Medium;
  margin-top: 30px;
  font-size: 20px;
}
h1.whoops {
  color: #201546;
  font-size: 40pt;
  font-weight: bold;
  text-align: center;
  font-family: GT-Haptik-Medium;
}
p.pageNotFound {
  text-align: center;
  font-weight: bold;
  font-family: GT-Haptik-Medium;
  margin-top: 30px;
  font-size: 20px;
}
li.nav-item.linkText {
  line-height: 18px;
}
hr.hrLine {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  border-top: 1px solid #1f1545;
}
h4.sideTitle.downTop {
  margin-top: -10px;
}
.downloadsLink {
  background-color: #cbebf9;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  border-radius: 5px;
  padding-right: 10px;
  margin-top: 10px;
}
.usefulLinks {
  background-color: #cbebf9;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
  margin-top: 10px;
  border-radius: 5px;
  padding-right: 10px;
}
.contentBlock {
  padding-left: 20%;
}
#content {
  padding-left: 20%;
}
.overlay {
  background-color: #201546;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/HomePage_Overlay_1@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.splash {
  opacity: 0.46;
  height: 603px;
  object-fit: cover;
}
.title {
  position: absolute;
  top: 277px;
  width: 100%;
  text-align: center;
  height: 75px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000058;
  font-weight: bold;
  font-size: 3.5rem;
}
.route {
  display: inline-flex;
}
.route > li > a {
  padding: 0.5rem 0;
  text-transform: uppercase;
  font-weight: bold;
}
.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.wp {
  padding-top: 50px;
}
h2 {
  padding: 50px 0 0 0;
  color: #201546;
  margin-bottom: -10px;
}
::v-deep .wpContent > h2 {
  padding: 30px 0;
  color: #201546;
  font-size: 24px;
  font-weight: 500;
}
::v-deep .wpContent > h2 > strong{
  font-weight: 500!important;
}
::v-deep .wpContent > p {
  color: #707070;
  font-size: 16px;
}
::v-deep .wpContent > figure {
  padding-top: 20px;
}
::v-deep img {
  object-fit: cover;
}
.faqQ {
  background-color: transparent;
  color: #009fe3!important;
  font-style: italic;
  border: 0;
  text-align: left;
}
.arrow {
  border: solid #009fe3;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  right: 10px;
  top: 15px;
}

.nav-item > a {
  color: #009fe3;
}
::v-deep .wpContent > ul > li {
  list-style-type: disc;
  color: #707070;
}
.hide0 {
  display: none;
}
::v-deep .sideTitle {
  color: #201546;
  font-size: 17px;
  font-weight: 100;
  padding-bottom: 20px;
  padding: 15px 0px 20px 0;
  padding-top: 20px;
  font-family: GT-Haptik-Medium;
  margin-bottom: -15px;
}
.route > li:first-child > a {
  text-decoration: underline;
}
.route > li:nth-child(2) > a {
  text-decoration: underline;
}
::v-deep .wpContent > p > a {
  color: #009fe3;
}
::v-deep table {
  width: 100%;
}
::v-deep th {
  background: #009fe3 0% 0% no-repeat padding-box;
  color: #fff;
  padding: 20px 16px 19px 21px;
}
::v-deep td {
  border: 4px solid #fff;
  padding: 17px 18px 17px 21px;
  font-size: 14px;
  color: #707070;
  width: 7%;
}
::v-deep .wpContent > ul > li > a {
  color: #000;
}
::v-deep tr {
  background-color: #f7f7f7;
}
.top {
  padding-top: 50px;
}
.bottom {
  padding-bottom: 30px;
}

@media only screen and (max-width: 768px) {
  ::v-deep .wpContent > figure {
    padding-top: 20px;
    overflow: scroll;
  }
}
@media only screen and (max-width: 990px) {
  #content {
    padding-left: 0;
  }
  .contentBlock {
    padding-left: 0%;
  }
  ::v-deep .wp {
    padding-top: 10px!important;
  }
}
</style>
